@import "../../../Constants/Dimensions.scss";
@import "../../../Constants/Colors.scss";

.wrapper {
    width: 400px;
    margin: 15px;
}

.background_image {
    padding: 30px 15px;
    width: 400px;
    min-height: 300px !important;
    margin-bottom: 20px;
    border-radius: 15px;
    background-repeat: no-repeat !important;
    background-size: cover;
    box-shadow: rgba(13, 13, 13, 0.1) 0px 0.25rem 0.375rem 0px;
    // -webkit-transform: scale(1);
    z-index: 1 !important;
    // -webkit-transition: 0.2s ease-in-out;
    // transition: all 0.2s ease-in-out;
    transform: scale(1);
    transition: transform 250ms;
    cursor: pointer;
    border: 8px solid $violet;

    .description {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        color: #fff;
    }

    .action_btn {
        margin: 25px 0;
        box-shadow: none !important;
    }
}

.title {
    color: $violet;
    width: 100%;
    text-align: center;

    h3 {
        font-size: 20px !important;
        color: $orange;
        width: 395px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // margin-bottom: 25px;
    }
}

.videoControl {
    width: 75px !important;
    height: 75px !important;
    color: $orange;
}

@media screen and (max-width: 408px) {
    .background_image {
        width: 350px;
        border: 5px solid $violet;
    }

    .title {
        h3 {
            width: 350px;
        }
    }
}

@media screen and (max-width: 350px) {
    .background_image {
        width: 280px;
        border: 5px solid $violet;
        background-image: none !important;
        background-color: $violet !important;
    }

    .title {
        h3 {
            width: 280px;
        }
    }
}